import React from 'react';

import { useHistory } from 'react-router';
import { useState } from 'react';


import { useDispatch } from 'react-redux';


import { useMediaQuery } from '@mui/material';
import AlertBox from '../components/common/AlertBox';
import OnboardingBox from '../components/OnboardingBox';
import Button from '../components/sub-component/Button';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { resendInvite } from '../redux/thunks/Auth';

function InviteLinkExpired() {
	const history = useHistory();
	const [processing, setProcessing] = useState(false);
	const [alert, setAlert] = useState(null);
	const dispatch = useDispatch();
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const search = useLocation().search;
	const searchParams = new URLSearchParams(search);
	const inviteToken = searchParams.get('invite_token');
	const assessId = searchParams.get('assess_id');
	const inviteId = searchParams.get('invite_id');



	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setProcessing(true);
			const response = await dispatch(resendInvite({ invite_token: inviteToken, assess_id: assessId, invite_id: inviteId }));
			setProcessing(false);
			setAlert(
				<AlertBox description="Invite send successfully!" color='success' title="Success" onCloseAlert={() => { setAlert(null); }} />
			);
			// history.push('/dashboard');
		} catch (error) {
			console.log(error, 'erro321');
			setProcessing(false);
			setAlert(
				<AlertBox description={`Error occurred: ${error.message}`} color='danger' title="Error" onCloseAlert={() => { setAlert(null); }} />
			);
		}
	};

	return (
		<>
			<OnboardingBox>
				<div
					className="d-flex flex-column login-form-container"
					style={{ width: '100%', margin: 'auto' }}
				>
					<div className="form">
						<div className={`${isOnMobileScreen || isOnTabletScreen ? 'hero-small' : 'headline-medium'} bold`}>Invitation Link Expired</div>
						<div className={`body-${isOnMobileScreen || isOnTabletScreen ? '1' : '3'} light`} style={{ marginTop: '10px' }}>
							{`Oops! It seems like the invitation link has expired. Don't worry, we can send you a new one. Just click the button below to receive another invitation.`}
						</div>
						<div>
							<form>
								<div className="login-input-group">
									<div className='mt-4'>
										{alert}
									</div>
									<div className="">
										<Button
											themeRectangleButton
											onClick={handleSubmit}
											processing={processing}
											type={'button'}
											title={'Resend Link'}
											style={{ height: '40px', marginTop: '32px' }}
										/>
									</div>

								</div>
							</form>
						</div>
					</div>
				</div>
			</OnboardingBox>
		</>
	);
}

export default InviteLinkExpired;
