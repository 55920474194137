import { createSlice } from '@reduxjs/toolkit';

import { fetchAppDetails } from '../thunks/OAuth';
const OAuth = createSlice({
	name: 'OAuth Slice',
	initialState: {
		loading: false,
		data: null,
		
	},

	reducers: {},

	extraReducers: {


		[fetchAppDetails.pending]: (state) => {
			return {
				...state,
				loading: true,
			};
		},

		[fetchAppDetails.fulfilled]: (state, { payload }) => {
			return {
				...state,
				loading: false,
				data: payload,
			};
		},

		[fetchAppDetails.rejected]: (state) => {
			return {
				...state,
				loading: false,
			};
		},


	},
});

export default OAuth.reducer;