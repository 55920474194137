import { configureStore } from '@reduxjs/toolkit';
import AssessSpecReducer from './slices/AssessSpec';
import OAuthReducer from './slices/OAuthSlice';
import AuthReducer from './slices/AuthSlice';
import TenantReducer from './slices/TenantSlice';
import QuesPromptReducer from './slices/QuesPromptSlice';
import ResourceReducer from './slices/ResourceSlice';
import SubscriptionReducer from './slices/SubscriptionSlice';
import TestPackReducer from './slices/TestPackSlice';
import UserReducer from './slices/UserSlice';
import AssessmentReducer from './slices/AssessmentSlice';
import UserProfileReducer from './slices/UserProfileSlice';
import CandidateReducer from './slices/CandidateSlice';
import NotificationReducer from './slices/NotificationSlice';
import ErrorBoundaryReducer from './slices/ErrorBoundarySlice';
import CustomerSupport from './slices/CustomerSupportSlice';
import PublicTestpackReducer from './slices/PublicTestpackSlice';
import AlertSliceReducer from './slices/AlertSlice';
import TestHistorySliceReducer from './slices/TestHistorySlice';
import PublicResultSliceReducer from './slices/PublicResultSlice';

export default configureStore({
	reducer: {
		authReducer: AuthReducer,
		tenantReducer: TenantReducer,
		subscriptionReducer: SubscriptionReducer,
		assessSpecReducer: AssessSpecReducer,
		testPackReducer: TestPackReducer,
		quesPromptReducer: QuesPromptReducer,
		resourceReducer: ResourceReducer,
		userReducer: UserReducer,
		assessmentReducer: AssessmentReducer,
		userProfileReducer: UserProfileReducer,
		CandidateReducer: CandidateReducer,
		notificationReducer: NotificationReducer,
		errorBoundaryReducer: ErrorBoundaryReducer,
		customerSupportReducer: CustomerSupport,
		publicTestpackReducer: PublicTestpackReducer,
		alertReducer: AlertSliceReducer,
		testHistoryReducer: TestHistorySliceReducer,
		publicResultReducer: PublicResultSliceReducer,
		oAuthReducer: OAuthReducer,
	},
	devTools: window._env_.NODE_ENV === 'development'
});