import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { useHistory } from 'react-router-dom';
import 'react-multi-email/style.css';
import IntiPic from '../../../../assets/images/HowItworks/image2.png';
import Button from '../../../../components/sub-component/Button';
import Input from '../../../../components/sub-component/Input';
import { inviteUsers } from '../../../../redux/thunks/User';
import AssessmentCreationHeader from '../../../../components/dashboard/assessments/AssessmentCreationHeader';
import { ReactComponent as BackArrowIcon } from '../../../../assets/icons/Views/dashboard/test-detail/left-arrow.svg';
import { ReactComponent as LeftArrowIcon } from '../../../../assets/icons/Views/dashboard/assessments/arrow-left.svg';
import { useEffect } from 'react';
import { getAssessSpec } from '../../../../redux/thunks/AssessSpec';
import AlertBox from '../../../../components/common/AlertBox';
import { RequestStatus, inviteBlock } from '../../../../utils/Constants';
import { calculateTimeDifferenceInDays } from '../../../../utils/utilities';
import { useMediaQuery } from '@mui/material';
import EmailCustomizationModal from './EmailCustomizationModal';
import { ReactComponent as AlertInfo } from '../../../../assets/icons/pricing/alert-triangle.svg';

export default function PublishAssessment(props) {

	const params = useParams();
	const [emailList, setEmailList] = useState([]);
	const [copied, setCopied] = useState(false);
	const [alert, setAlert] = useState(null);
	const [openEmailCustomizationModal, setOpenEmailCustomizationModal] = useState(false);
	const isOnTablet = useMediaQuery('(min-width: 768px) and (max-width: 1439px)');
	const isMobile = useMediaQuery('(max-width: 767px)');
	const history = useHistory();
	// const [error, setError] = useState();

	const users = useSelector((state) => state.userReducer);
	const auth = useSelector((state) => state.authReducer);
	const assessSpec = useSelector((state) => state.assessSpecReducer);
	const dispatch = useDispatch();

	const subscription = useSelector((state) => state.subscriptionReducer);
	const expireAt = subscription?.user_subscription.length > 0 ? subscription?.user_subscription[0]?.expires_at : null;
	const daysLeft = calculateTimeDifferenceInDays(expireAt);
	const openLeftDaysDisclaimer = sessionStorage.getItem('openLeftDaysDisclaimer');

	const getTitle = (title) => {
		if (isOnTablet && title?.length > 42) {
			return title?.slice(0, 42) + '...';
		}
		if (isMobile && title?.length > 45) {
			return title?.slice(0, 45) + '...';
		}
		return title;
	};

	const { routeDetails } = props;
	const inviteLink = useMemo(() => {

		return `${window.location.origin}/?invite_id=${assessSpec.curr_assessment_spec?.invite_id}`;

	}, [assessSpec]);


	// const handleChange = (event) => {

	// 	const emails = event.target.value.split(',');
	// 	var hasError = false;
	// 	emails.forEach((email) => {
	// 		if (!Utils.isEmailValid(email)) {
	// 			hasError = true;
	// 		}
	// 	});

	// 	if (hasError) {
	// 		setError('One of the email is invalid');
	// 		return;
	// 	}

	// 	setError('');

	// 	setEmailList(emails);

	// };
	const handleNavigate = () => {
		const fromDashboard = history.location?.state?.fromDetails;
		if (fromDashboard) {
			history.goBack();
		} else {
			history.push('/dashboard');
		}
	};


	const handleInviteCandidates = async () => {

		if (!emailList.length) {
			return;
		}
		const { payload, meta } = await await dispatch(inviteUsers({ spec_id: params.id, emails: emailList }));
		if (meta?.requestStatus === RequestStatus.REJECTED) {
			setAlert(<AlertBox
				color='danger'
				description={payload}
				title='Error'
				isOpen={true}
				onCloseAlert={() => { setAlert(null); }}
			/>);
		}
		else if (meta?.requestStatus === RequestStatus.FULFILLED) {
			setAlert(<AlertBox
				color='success'
				description={payload?.message}
				title='Success'
				isOpen={true}
				onCloseAlert={() => { setAlert(null); }}
			/>);
			setEmailList([]);
		}

	};

	useEffect(() => {
		dispatch(getAssessSpec({ id: params.id }));
	}, []);



	return (
		<>
			{
				alert
			}
			<EmailCustomizationModal setMessage={setAlert} curr_assessment_spec={assessSpec.curr_assessment_spec} open={openEmailCustomizationModal} handleClose={() => { setOpenEmailCustomizationModal(false); }} isMobile={isMobile} setAlert={setAlert} />

			{assessSpec.loading ?
				<div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', height: '25px', width: '25px', margin: 'auto' }}>
					<div className='processing-indicator primary' style={{ width: '100%', height: '100%' }} />
				</div>
				:
				<div className='dashboard-page hide-scrollbar'>
					{inviteBlock.includes(auth?.data?.tenant_status) &&
						<div className='mask'>
							<div className='payment-message'>
								<AlertInfo />
								<span className='body-2'>You cannot share an assessment unless you fix your billing issues. Choose a plan from billing settings or pay your pending invoices. Please contact the Account Owner if you do not have the required permissions.</span>
							</div>
						</div>
					}
					{/* <div className='page-header'>
						<div className='d-flex align-items-center' style={{ gap: '12px' }}>
							<span className={`headline-3 dark-100`}>{`Share Assessments`}</span>
						</div>
					</div> */}
					<div className={`${(daysLeft < 6 && auth?.data?.tenant_status === 'trialing' && !openLeftDaysDisclaimer) && 'left-day-message-main-box'} page-content`}>
						<div className='publish-assessment-main-container w-100'>
							{/* <div className='w-100 d-flex align-items-start'>
								<AssessmentCreationHeader
									onTabAddSectionStepNo={() => { }}
									variant='primary'
									getOnTabAddSectionStepNo={() => { }}
									assessment={{ title: getTitle(assessSpec?.curr_assessment_spec?.title), updated_at: assessSpec?.curr_assessment_spec?.updated_at }}
									onConfirmTitle={() => { }}
									totalDuration={Math.floor(assessSpec?.curr_assessment_spec?.objective_test_duration) || Math.floor(assessSpec?.curr_assessment_spec?.duration) || 0}
									isObjectiveTime={(assessSpec?.curr_assessment_spec?.objective_test_duration !== undefined || assessSpec?.curr_assessment_spec?.objective_test_duration !== null) ? true : false}
									handleNavigate={handleNavigate}
									// currentPage={'publish'}
									// currentStep={currentStep}
									// getCurrentSetp={handleConfirmAssessmentSpec}
									// getCurrentSetp={(currStep) => { setCurrentStep(currStep); }}
								/>
							</div> */}
							<div className='publish-assessment-header'>
								<div className='w-100 d-flex align-items-center' style={{gap: '16px'}}>
									<div className='icon-cover' onClick={handleNavigate}>
										<LeftArrowIcon />
									</div>
									<span className='body-2 dark-50 truncate-assessment-title'>{assessSpec?.curr_assessment_spec?.title}</span>
								</div>
								<span className='headline-3 text-start dark-100'>Share Assessment</span>
							</div>
							<div className='publish-assessment-container'>
								<div className='publish-assessment-child-container '>
									<span className='headline-4 dark-100'>Public Link</span>
									<div className='w-100 d-flex flex-row align-items-end justify-content-between' style={{ gap: '10px', marginBottom: '15px' }}>
										<Input
											disabled={true}
											varrient='primary'
											className='body-3 primary-input'
											title={'link'}
											placeholder={inviteBlock.includes(auth?.data?.tenant_status) ? '' : 'http:linkwillbeshownhere/'}
											value={inviteBlock.includes(auth?.data?.tenant_status) ? '' : inviteLink}
											style={{ maxWidth: 'calc(100% - 130px)' }}
										/>
										<Button
											btn='ragular-btn'
											varrient='primary'
											disabled={copied}
											title={copied ? 'Copied' : 'Copy Link'}
											style={{ width: '120px' }}
											onClick={() => {
												navigator.clipboard.writeText(inviteLink);
												setCopied(true);
												setTimeout(() => setCopied(false), 1000);
											}}
										/>
									</div>
								</div>
								<div className='publish-assessment-child-container'>
									<div style={{ justifyContent: 'space-between' }} className='d-flex w-100'>
										<span className='headline-4 dark-100 text-start'>Invite by Email</span>
										{isMobile &&
											<Button
												btn='ragular-btn'
												varrient='secondary-btn'
												title={'Customize Email'}
												style={{ width: '170px' }}
												onClick={() => setOpenEmailCustomizationModal(true)}
											/>
										}
									</div>
									<div className='w-100 d-flex flex-column align-items-start' style={{ gap: '4px' }}>
										<div className='w-100 d-flex flex-row align-items-end email-list' style={{ gap: '10px' }}>
											<div className='d-flex flex-column align-items-start' style={{ width: `calc(100% - ${isMobile ? '70px' : '280px'})` }}>
												<span className='body-2 dark-50 text-start mb-2' style={{ minWidth: 'fit-content', paddingLeft: '24px' }}>Email</span>
												<div className='w-100'>
													<ReactMultiEmail
														placeholder='Email'
														emails={emailList}
														onChange={(_emails) => {
															setEmailList(_emails);
														}}
														validateEmail={email => {
															// Validate email only if the limit is not reached
															if (emailList.length >= 9) {
																return false; // Prevent adding more emails
															}
															return isEmail(email); // return boolean
														}}
														getLabel={(email, index, removeEmail) => {
															return (
																<div data-tag key={index}>
																	{email}
																	<span data-tag-handle onClick={() => removeEmail(index)}>
																		×
																	</span>
																</div>
															);
														}}
													/>
												</div>
											</div>
											<Button
												btn='ragular-btn'
												varrient='primary'
												title={'Invite'}
												processing={users.processing}
												onClick={handleInviteCandidates}
											/>
											{!isMobile &&
												<Button
													btn='ragular-btn'
													varrient='secondary-btn'
													title={'Customize Email'}
													style={{ width: '170px' }}
													onClick={() => setOpenEmailCustomizationModal(true)}
												/>
											}
										</div>
										<span className='body-3 dark-50' style={{ marginLeft: '24px' }}>Use space to add more than one email</span>
									</div>
								</div>
							</div>
							<div className='integration-public-assessment-container'>
								<span className='headline-4 dark-100'>Integrations</span>
								<zapier-zap-templates
									theme='light'
									apps='testfuse'
									categories='hr,crm'
									create-without-template='show'
									limit={3}
									link-target='new-tab'
									use-this-zap='show'
								/>
							</div>
						</div>
					</div>
				</div>
			}
		</>

	);
}
