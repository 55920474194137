import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';
import axios from 'axios';
const cancle_tocken = axios.CancelToken;

export const oauthAuthorization = createAsyncThunk(
	'oauth/authorize',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const search_params = new URLSearchParams(data);

			const request = await secure_instance.request({
				url: '/v1/oauth/authorize',
				method: 'POST',
				data: {
					response_type: search_params.get('response_type'),
					client_id: search_params.get('client_id'),
					state: search_params.get('state'),
					redirect_uri: search_params.get('redirect_uri'),
				},
			});

			window.location.href = request.data.redirect_uri;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

let fetchAppDetailsApiCancel = null;
export const fetchAppDetails = createAsyncThunk(
	'oauth/fetchAppDetails',
	async ({ data }, { rejectWithValue, dispatch }) => {

		// Cancel the request if there is a previous one
		if (fetchAppDetailsApiCancel) {
			fetchAppDetailsApiCancel();
		}
		try {
			// const search_params = new URLSearchParams(data);
			// Perform the API request
			const request = await secure_instance.request({
				url: `/v1/oauth/app_details?client_id=${data.client_id}`,
				// url: `/v1/oauth/app_details?client_id=b6f4ba42b6c7c8dacb3f3d5b40e443da`,
				method: 'GET',
				cancelToken: new cancle_tocken((c) => {
					fetchAppDetailsApiCancel = c;
				})
			});

			// Return the data
			return request.data;
		} catch (error) {
			// Handle errors
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

